import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/download'),
    meta: {
      title: '下载'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
